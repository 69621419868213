import { defineStore } from 'pinia'
import { type ICart, type ICartItemUpdate } from '~/types/ICart'
import { useAuthenticatedFetch } from '~/composables/useAuthenticatedFetch'

export const useCartStore = defineStore('cart', {
  state: (): ICart => ({
    id: '',
    total: 0,
    items: [],
    promos: [],
    updated_at: '',
    total_items: 0,
    total_positions: 0,
  }),
  actions: {
    async fetch() {
      const { data, execute } = useAuthenticatedFetch(`/api/v1/shop/cart`, {
        immediate: false,
        watch: false
      })
      await execute()
      Object.assign(this.$state, data.value)
    },
    getQuantityById(id: string) {
      const item = this.items.find((item) => item.item_id === id)
      if (item?.quantity) return item.quantity
      return 0
    },
    async update(product: ICartItemUpdate) {
      const itemInCartIndex = this.items.findIndex((item) => item.item_id === product.item_id)
      const needCreation = itemInCartIndex === -1
      if (needCreation) {
        await this.create(product)
      } else if (product.quantity !== 0) {
        const cartItemId = this.items[itemInCartIndex].id
        const { data, execute } = useAuthenticatedFetch<ICart>(`/api/v1/shop/cart/${cartItemId}`, {
          method: 'PUT',
          body: {
            quantity: product.quantity
          },
          immediate: false,
          watch: false
        })
        await execute()
        Object.assign(this.$state, data.value)
      } else if (product.quantity === 0) {
        await this.remove(product)
      }
    },
    async create(product: ICartItemUpdate) {
      const { data, execute } = useAuthenticatedFetch<ICart>('/api/v1/shop/cart', {
        method: 'POST',
        body: product,
        immediate: false,
        watch: false
      })
      await execute()
      Object.assign(this.$state, data.value)
    },
    async remove(product: ICartItemUpdate) {
      const cartItemId = this.items.find((item) => item.item_id === product.item_id)?.id
      const { data, execute } = useAuthenticatedFetch<ICart>(`/api/v1/shop/cart/${cartItemId}`, {
        method: 'DELETE',
        immediate: false,
        watch: false
      })
      await execute()
      Object.assign(this.$state, data.value)
    }
  }
})
